/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.instantbible = (function() {

    /**
     * Namespace instantbible.
     * @exports instantbible
     * @namespace
     */
    var instantbible = {};

    instantbible.data = (function() {

        /**
         * Namespace data.
         * @memberof instantbible
         * @namespace
         */
        var data = {};

        /**
         * Translation enum.
         * @name instantbible.data.Translation
         * @enum {number}
         * @property {number} KJV=0 KJV value
         * @property {number} NET=1 NET value
         * @property {number} BSB=2 BSB value
         * @property {number} TOTAL=3 TOTAL value
         */
        data.Translation = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "KJV"] = 0;
            values[valuesById[1] = "NET"] = 1;
            values[valuesById[2] = "BSB"] = 2;
            values[valuesById[3] = "TOTAL"] = 3;
            return values;
        })();

        /**
         * Book enum.
         * @name instantbible.data.Book
         * @enum {number}
         * @property {number} GENESIS=0 GENESIS value
         * @property {number} EXODUS=1 EXODUS value
         * @property {number} LEVITICUS=2 LEVITICUS value
         * @property {number} NUMBERS=3 NUMBERS value
         * @property {number} DEUTERONOMY=4 DEUTERONOMY value
         * @property {number} JOSHUA=5 JOSHUA value
         * @property {number} JUDGES=6 JUDGES value
         * @property {number} RUTH=7 RUTH value
         * @property {number} FIRST_SAMUEL=8 FIRST_SAMUEL value
         * @property {number} SECOND_SAMUEL=9 SECOND_SAMUEL value
         * @property {number} FIRST_KINGS=10 FIRST_KINGS value
         * @property {number} SECOND_KINGS=11 SECOND_KINGS value
         * @property {number} FIRST_CHRONICLES=12 FIRST_CHRONICLES value
         * @property {number} SECOND_CHRONICLES=13 SECOND_CHRONICLES value
         * @property {number} EZRA=14 EZRA value
         * @property {number} NEHEMIAH=15 NEHEMIAH value
         * @property {number} ESTHER=16 ESTHER value
         * @property {number} JOB=17 JOB value
         * @property {number} PSALMS=18 PSALMS value
         * @property {number} PROVERBS=19 PROVERBS value
         * @property {number} ECCLESIASTES=20 ECCLESIASTES value
         * @property {number} SONG_OF_SOLOMON=21 SONG_OF_SOLOMON value
         * @property {number} ISAIAH=22 ISAIAH value
         * @property {number} JEREMIAH=23 JEREMIAH value
         * @property {number} LAMENTATIONS=24 LAMENTATIONS value
         * @property {number} EZEKIEL=25 EZEKIEL value
         * @property {number} DANIEL=26 DANIEL value
         * @property {number} HOSEA=27 HOSEA value
         * @property {number} JOEL=28 JOEL value
         * @property {number} AMOS=29 AMOS value
         * @property {number} OBADIAH=30 OBADIAH value
         * @property {number} JONAH=31 JONAH value
         * @property {number} MICAH=32 MICAH value
         * @property {number} NAHUM=33 NAHUM value
         * @property {number} HABAKKUK=34 HABAKKUK value
         * @property {number} ZEPHANIAH=35 ZEPHANIAH value
         * @property {number} HAGGAI=36 HAGGAI value
         * @property {number} ZECHARIAH=37 ZECHARIAH value
         * @property {number} MALACHI=38 MALACHI value
         * @property {number} MATTHEW=39 MATTHEW value
         * @property {number} MARK=40 MARK value
         * @property {number} LUKE=41 LUKE value
         * @property {number} JOHN=42 JOHN value
         * @property {number} ACTS=43 ACTS value
         * @property {number} ROMANS=44 ROMANS value
         * @property {number} FIRST_CORINTHIANS=45 FIRST_CORINTHIANS value
         * @property {number} SECOND_CORINTHIANS=46 SECOND_CORINTHIANS value
         * @property {number} GALATIANS=47 GALATIANS value
         * @property {number} EPHESIANS=48 EPHESIANS value
         * @property {number} PHILIPPIANS=49 PHILIPPIANS value
         * @property {number} COLOSSIANS=50 COLOSSIANS value
         * @property {number} FIRST_THESSALONIANS=51 FIRST_THESSALONIANS value
         * @property {number} SECOND_THESSALONIANS=52 SECOND_THESSALONIANS value
         * @property {number} FIRST_TIMOTHY=53 FIRST_TIMOTHY value
         * @property {number} SECOND_TIMOTHY=54 SECOND_TIMOTHY value
         * @property {number} TITUS=55 TITUS value
         * @property {number} PHILEMON=56 PHILEMON value
         * @property {number} HEBREWS=57 HEBREWS value
         * @property {number} JAMES=58 JAMES value
         * @property {number} FIRST_PETER=59 FIRST_PETER value
         * @property {number} SECOND_PETER=60 SECOND_PETER value
         * @property {number} FIRST_JOHN=61 FIRST_JOHN value
         * @property {number} SECOND_JOHN=62 SECOND_JOHN value
         * @property {number} THIRD_JOHN=63 THIRD_JOHN value
         * @property {number} JUDE=64 JUDE value
         * @property {number} REVELATION=65 REVELATION value
         */
        data.Book = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GENESIS"] = 0;
            values[valuesById[1] = "EXODUS"] = 1;
            values[valuesById[2] = "LEVITICUS"] = 2;
            values[valuesById[3] = "NUMBERS"] = 3;
            values[valuesById[4] = "DEUTERONOMY"] = 4;
            values[valuesById[5] = "JOSHUA"] = 5;
            values[valuesById[6] = "JUDGES"] = 6;
            values[valuesById[7] = "RUTH"] = 7;
            values[valuesById[8] = "FIRST_SAMUEL"] = 8;
            values[valuesById[9] = "SECOND_SAMUEL"] = 9;
            values[valuesById[10] = "FIRST_KINGS"] = 10;
            values[valuesById[11] = "SECOND_KINGS"] = 11;
            values[valuesById[12] = "FIRST_CHRONICLES"] = 12;
            values[valuesById[13] = "SECOND_CHRONICLES"] = 13;
            values[valuesById[14] = "EZRA"] = 14;
            values[valuesById[15] = "NEHEMIAH"] = 15;
            values[valuesById[16] = "ESTHER"] = 16;
            values[valuesById[17] = "JOB"] = 17;
            values[valuesById[18] = "PSALMS"] = 18;
            values[valuesById[19] = "PROVERBS"] = 19;
            values[valuesById[20] = "ECCLESIASTES"] = 20;
            values[valuesById[21] = "SONG_OF_SOLOMON"] = 21;
            values[valuesById[22] = "ISAIAH"] = 22;
            values[valuesById[23] = "JEREMIAH"] = 23;
            values[valuesById[24] = "LAMENTATIONS"] = 24;
            values[valuesById[25] = "EZEKIEL"] = 25;
            values[valuesById[26] = "DANIEL"] = 26;
            values[valuesById[27] = "HOSEA"] = 27;
            values[valuesById[28] = "JOEL"] = 28;
            values[valuesById[29] = "AMOS"] = 29;
            values[valuesById[30] = "OBADIAH"] = 30;
            values[valuesById[31] = "JONAH"] = 31;
            values[valuesById[32] = "MICAH"] = 32;
            values[valuesById[33] = "NAHUM"] = 33;
            values[valuesById[34] = "HABAKKUK"] = 34;
            values[valuesById[35] = "ZEPHANIAH"] = 35;
            values[valuesById[36] = "HAGGAI"] = 36;
            values[valuesById[37] = "ZECHARIAH"] = 37;
            values[valuesById[38] = "MALACHI"] = 38;
            values[valuesById[39] = "MATTHEW"] = 39;
            values[valuesById[40] = "MARK"] = 40;
            values[valuesById[41] = "LUKE"] = 41;
            values[valuesById[42] = "JOHN"] = 42;
            values[valuesById[43] = "ACTS"] = 43;
            values[valuesById[44] = "ROMANS"] = 44;
            values[valuesById[45] = "FIRST_CORINTHIANS"] = 45;
            values[valuesById[46] = "SECOND_CORINTHIANS"] = 46;
            values[valuesById[47] = "GALATIANS"] = 47;
            values[valuesById[48] = "EPHESIANS"] = 48;
            values[valuesById[49] = "PHILIPPIANS"] = 49;
            values[valuesById[50] = "COLOSSIANS"] = 50;
            values[valuesById[51] = "FIRST_THESSALONIANS"] = 51;
            values[valuesById[52] = "SECOND_THESSALONIANS"] = 52;
            values[valuesById[53] = "FIRST_TIMOTHY"] = 53;
            values[valuesById[54] = "SECOND_TIMOTHY"] = 54;
            values[valuesById[55] = "TITUS"] = 55;
            values[valuesById[56] = "PHILEMON"] = 56;
            values[valuesById[57] = "HEBREWS"] = 57;
            values[valuesById[58] = "JAMES"] = 58;
            values[valuesById[59] = "FIRST_PETER"] = 59;
            values[valuesById[60] = "SECOND_PETER"] = 60;
            values[valuesById[61] = "FIRST_JOHN"] = 61;
            values[valuesById[62] = "SECOND_JOHN"] = 62;
            values[valuesById[63] = "THIRD_JOHN"] = 63;
            values[valuesById[64] = "JUDE"] = 64;
            values[valuesById[65] = "REVELATION"] = 65;
            return values;
        })();

        data.VerseKey = (function() {

            /**
             * Properties of a VerseKey.
             * @memberof instantbible.data
             * @interface IVerseKey
             * @property {instantbible.data.Book|null} [book] VerseKey book
             * @property {number|null} [chapter] VerseKey chapter
             * @property {number|null} [verse] VerseKey verse
             */

            /**
             * Constructs a new VerseKey.
             * @memberof instantbible.data
             * @classdesc Represents a VerseKey.
             * @implements IVerseKey
             * @constructor
             * @param {instantbible.data.IVerseKey=} [properties] Properties to set
             */
            function VerseKey(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VerseKey book.
             * @member {instantbible.data.Book} book
             * @memberof instantbible.data.VerseKey
             * @instance
             */
            VerseKey.prototype.book = 0;

            /**
             * VerseKey chapter.
             * @member {number} chapter
             * @memberof instantbible.data.VerseKey
             * @instance
             */
            VerseKey.prototype.chapter = 0;

            /**
             * VerseKey verse.
             * @member {number} verse
             * @memberof instantbible.data.VerseKey
             * @instance
             */
            VerseKey.prototype.verse = 0;

            /**
             * Creates a new VerseKey instance using the specified properties.
             * @function create
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {instantbible.data.IVerseKey=} [properties] Properties to set
             * @returns {instantbible.data.VerseKey} VerseKey instance
             */
            VerseKey.create = function create(properties) {
                return new VerseKey(properties);
            };

            /**
             * Encodes the specified VerseKey message. Does not implicitly {@link instantbible.data.VerseKey.verify|verify} messages.
             * @function encode
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {instantbible.data.IVerseKey} message VerseKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerseKey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.book != null && Object.hasOwnProperty.call(message, "book"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.book);
                if (message.chapter != null && Object.hasOwnProperty.call(message, "chapter"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.chapter);
                if (message.verse != null && Object.hasOwnProperty.call(message, "verse"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.verse);
                return writer;
            };

            /**
             * Encodes the specified VerseKey message, length delimited. Does not implicitly {@link instantbible.data.VerseKey.verify|verify} messages.
             * @function encodeDelimited
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {instantbible.data.IVerseKey} message VerseKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerseKey.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerseKey message from the specified reader or buffer.
             * @function decode
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {instantbible.data.VerseKey} VerseKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerseKey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.data.VerseKey();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.book = reader.int32();
                        break;
                    case 2:
                        message.chapter = reader.uint32();
                        break;
                    case 3:
                        message.verse = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerseKey message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {instantbible.data.VerseKey} VerseKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerseKey.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerseKey message.
             * @function verify
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerseKey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.book != null && message.hasOwnProperty("book"))
                    switch (message.book) {
                    default:
                        return "book: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 24:
                    case 25:
                    case 26:
                    case 27:
                    case 28:
                    case 29:
                    case 30:
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                    case 35:
                    case 36:
                    case 37:
                    case 38:
                    case 39:
                    case 40:
                    case 41:
                    case 42:
                    case 43:
                    case 44:
                    case 45:
                    case 46:
                    case 47:
                    case 48:
                    case 49:
                    case 50:
                    case 51:
                    case 52:
                    case 53:
                    case 54:
                    case 55:
                    case 56:
                    case 57:
                    case 58:
                    case 59:
                    case 60:
                    case 61:
                    case 62:
                    case 63:
                    case 64:
                    case 65:
                        break;
                    }
                if (message.chapter != null && message.hasOwnProperty("chapter"))
                    if (!$util.isInteger(message.chapter))
                        return "chapter: integer expected";
                if (message.verse != null && message.hasOwnProperty("verse"))
                    if (!$util.isInteger(message.verse))
                        return "verse: integer expected";
                return null;
            };

            /**
             * Creates a VerseKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {instantbible.data.VerseKey} VerseKey
             */
            VerseKey.fromObject = function fromObject(object) {
                if (object instanceof $root.instantbible.data.VerseKey)
                    return object;
                var message = new $root.instantbible.data.VerseKey();
                switch (object.book) {
                case "GENESIS":
                case 0:
                    message.book = 0;
                    break;
                case "EXODUS":
                case 1:
                    message.book = 1;
                    break;
                case "LEVITICUS":
                case 2:
                    message.book = 2;
                    break;
                case "NUMBERS":
                case 3:
                    message.book = 3;
                    break;
                case "DEUTERONOMY":
                case 4:
                    message.book = 4;
                    break;
                case "JOSHUA":
                case 5:
                    message.book = 5;
                    break;
                case "JUDGES":
                case 6:
                    message.book = 6;
                    break;
                case "RUTH":
                case 7:
                    message.book = 7;
                    break;
                case "FIRST_SAMUEL":
                case 8:
                    message.book = 8;
                    break;
                case "SECOND_SAMUEL":
                case 9:
                    message.book = 9;
                    break;
                case "FIRST_KINGS":
                case 10:
                    message.book = 10;
                    break;
                case "SECOND_KINGS":
                case 11:
                    message.book = 11;
                    break;
                case "FIRST_CHRONICLES":
                case 12:
                    message.book = 12;
                    break;
                case "SECOND_CHRONICLES":
                case 13:
                    message.book = 13;
                    break;
                case "EZRA":
                case 14:
                    message.book = 14;
                    break;
                case "NEHEMIAH":
                case 15:
                    message.book = 15;
                    break;
                case "ESTHER":
                case 16:
                    message.book = 16;
                    break;
                case "JOB":
                case 17:
                    message.book = 17;
                    break;
                case "PSALMS":
                case 18:
                    message.book = 18;
                    break;
                case "PROVERBS":
                case 19:
                    message.book = 19;
                    break;
                case "ECCLESIASTES":
                case 20:
                    message.book = 20;
                    break;
                case "SONG_OF_SOLOMON":
                case 21:
                    message.book = 21;
                    break;
                case "ISAIAH":
                case 22:
                    message.book = 22;
                    break;
                case "JEREMIAH":
                case 23:
                    message.book = 23;
                    break;
                case "LAMENTATIONS":
                case 24:
                    message.book = 24;
                    break;
                case "EZEKIEL":
                case 25:
                    message.book = 25;
                    break;
                case "DANIEL":
                case 26:
                    message.book = 26;
                    break;
                case "HOSEA":
                case 27:
                    message.book = 27;
                    break;
                case "JOEL":
                case 28:
                    message.book = 28;
                    break;
                case "AMOS":
                case 29:
                    message.book = 29;
                    break;
                case "OBADIAH":
                case 30:
                    message.book = 30;
                    break;
                case "JONAH":
                case 31:
                    message.book = 31;
                    break;
                case "MICAH":
                case 32:
                    message.book = 32;
                    break;
                case "NAHUM":
                case 33:
                    message.book = 33;
                    break;
                case "HABAKKUK":
                case 34:
                    message.book = 34;
                    break;
                case "ZEPHANIAH":
                case 35:
                    message.book = 35;
                    break;
                case "HAGGAI":
                case 36:
                    message.book = 36;
                    break;
                case "ZECHARIAH":
                case 37:
                    message.book = 37;
                    break;
                case "MALACHI":
                case 38:
                    message.book = 38;
                    break;
                case "MATTHEW":
                case 39:
                    message.book = 39;
                    break;
                case "MARK":
                case 40:
                    message.book = 40;
                    break;
                case "LUKE":
                case 41:
                    message.book = 41;
                    break;
                case "JOHN":
                case 42:
                    message.book = 42;
                    break;
                case "ACTS":
                case 43:
                    message.book = 43;
                    break;
                case "ROMANS":
                case 44:
                    message.book = 44;
                    break;
                case "FIRST_CORINTHIANS":
                case 45:
                    message.book = 45;
                    break;
                case "SECOND_CORINTHIANS":
                case 46:
                    message.book = 46;
                    break;
                case "GALATIANS":
                case 47:
                    message.book = 47;
                    break;
                case "EPHESIANS":
                case 48:
                    message.book = 48;
                    break;
                case "PHILIPPIANS":
                case 49:
                    message.book = 49;
                    break;
                case "COLOSSIANS":
                case 50:
                    message.book = 50;
                    break;
                case "FIRST_THESSALONIANS":
                case 51:
                    message.book = 51;
                    break;
                case "SECOND_THESSALONIANS":
                case 52:
                    message.book = 52;
                    break;
                case "FIRST_TIMOTHY":
                case 53:
                    message.book = 53;
                    break;
                case "SECOND_TIMOTHY":
                case 54:
                    message.book = 54;
                    break;
                case "TITUS":
                case 55:
                    message.book = 55;
                    break;
                case "PHILEMON":
                case 56:
                    message.book = 56;
                    break;
                case "HEBREWS":
                case 57:
                    message.book = 57;
                    break;
                case "JAMES":
                case 58:
                    message.book = 58;
                    break;
                case "FIRST_PETER":
                case 59:
                    message.book = 59;
                    break;
                case "SECOND_PETER":
                case 60:
                    message.book = 60;
                    break;
                case "FIRST_JOHN":
                case 61:
                    message.book = 61;
                    break;
                case "SECOND_JOHN":
                case 62:
                    message.book = 62;
                    break;
                case "THIRD_JOHN":
                case 63:
                    message.book = 63;
                    break;
                case "JUDE":
                case 64:
                    message.book = 64;
                    break;
                case "REVELATION":
                case 65:
                    message.book = 65;
                    break;
                }
                if (object.chapter != null)
                    message.chapter = object.chapter >>> 0;
                if (object.verse != null)
                    message.verse = object.verse >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a VerseKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof instantbible.data.VerseKey
             * @static
             * @param {instantbible.data.VerseKey} message VerseKey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerseKey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.book = options.enums === String ? "GENESIS" : 0;
                    object.chapter = 0;
                    object.verse = 0;
                }
                if (message.book != null && message.hasOwnProperty("book"))
                    object.book = options.enums === String ? $root.instantbible.data.Book[message.book] : message.book;
                if (message.chapter != null && message.hasOwnProperty("chapter"))
                    object.chapter = message.chapter;
                if (message.verse != null && message.hasOwnProperty("verse"))
                    object.verse = message.verse;
                return object;
            };

            /**
             * Converts this VerseKey to JSON.
             * @function toJSON
             * @memberof instantbible.data.VerseKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerseKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return VerseKey;
        })();

        data.VerseText = (function() {

            /**
             * Properties of a VerseText.
             * @memberof instantbible.data
             * @interface IVerseText
             * @property {instantbible.data.IVerseKey|null} [key] VerseText key
             * @property {string|null} [text] VerseText text
             */

            /**
             * Constructs a new VerseText.
             * @memberof instantbible.data
             * @classdesc Represents a VerseText.
             * @implements IVerseText
             * @constructor
             * @param {instantbible.data.IVerseText=} [properties] Properties to set
             */
            function VerseText(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VerseText key.
             * @member {instantbible.data.IVerseKey|null|undefined} key
             * @memberof instantbible.data.VerseText
             * @instance
             */
            VerseText.prototype.key = null;

            /**
             * VerseText text.
             * @member {string} text
             * @memberof instantbible.data.VerseText
             * @instance
             */
            VerseText.prototype.text = "";

            /**
             * Creates a new VerseText instance using the specified properties.
             * @function create
             * @memberof instantbible.data.VerseText
             * @static
             * @param {instantbible.data.IVerseText=} [properties] Properties to set
             * @returns {instantbible.data.VerseText} VerseText instance
             */
            VerseText.create = function create(properties) {
                return new VerseText(properties);
            };

            /**
             * Encodes the specified VerseText message. Does not implicitly {@link instantbible.data.VerseText.verify|verify} messages.
             * @function encode
             * @memberof instantbible.data.VerseText
             * @static
             * @param {instantbible.data.IVerseText} message VerseText message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerseText.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    $root.instantbible.data.VerseKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
                return writer;
            };

            /**
             * Encodes the specified VerseText message, length delimited. Does not implicitly {@link instantbible.data.VerseText.verify|verify} messages.
             * @function encodeDelimited
             * @memberof instantbible.data.VerseText
             * @static
             * @param {instantbible.data.IVerseText} message VerseText message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerseText.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerseText message from the specified reader or buffer.
             * @function decode
             * @memberof instantbible.data.VerseText
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {instantbible.data.VerseText} VerseText
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerseText.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.data.VerseText();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.key = $root.instantbible.data.VerseKey.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.text = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerseText message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof instantbible.data.VerseText
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {instantbible.data.VerseText} VerseText
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerseText.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerseText message.
             * @function verify
             * @memberof instantbible.data.VerseText
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerseText.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.key != null && message.hasOwnProperty("key")) {
                    var error = $root.instantbible.data.VerseKey.verify(message.key);
                    if (error)
                        return "key." + error;
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                return null;
            };

            /**
             * Creates a VerseText message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof instantbible.data.VerseText
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {instantbible.data.VerseText} VerseText
             */
            VerseText.fromObject = function fromObject(object) {
                if (object instanceof $root.instantbible.data.VerseText)
                    return object;
                var message = new $root.instantbible.data.VerseText();
                if (object.key != null) {
                    if (typeof object.key !== "object")
                        throw TypeError(".instantbible.data.VerseText.key: object expected");
                    message.key = $root.instantbible.data.VerseKey.fromObject(object.key);
                }
                if (object.text != null)
                    message.text = String(object.text);
                return message;
            };

            /**
             * Creates a plain object from a VerseText message. Also converts values to other types if specified.
             * @function toObject
             * @memberof instantbible.data.VerseText
             * @static
             * @param {instantbible.data.VerseText} message VerseText
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerseText.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.key = null;
                    object.text = "";
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = $root.instantbible.data.VerseKey.toObject(message.key, options);
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                return object;
            };

            /**
             * Converts this VerseText to JSON.
             * @function toJSON
             * @memberof instantbible.data.VerseText
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerseText.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return VerseText;
        })();

        data.TranslationData = (function() {

            /**
             * Properties of a TranslationData.
             * @memberof instantbible.data
             * @interface ITranslationData
             * @property {instantbible.data.Translation|null} [translation] TranslationData translation
             * @property {Array.<instantbible.data.IVerseText>|null} [verses] TranslationData verses
             */

            /**
             * Constructs a new TranslationData.
             * @memberof instantbible.data
             * @classdesc Represents a TranslationData.
             * @implements ITranslationData
             * @constructor
             * @param {instantbible.data.ITranslationData=} [properties] Properties to set
             */
            function TranslationData(properties) {
                this.verses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TranslationData translation.
             * @member {instantbible.data.Translation} translation
             * @memberof instantbible.data.TranslationData
             * @instance
             */
            TranslationData.prototype.translation = 0;

            /**
             * TranslationData verses.
             * @member {Array.<instantbible.data.IVerseText>} verses
             * @memberof instantbible.data.TranslationData
             * @instance
             */
            TranslationData.prototype.verses = $util.emptyArray;

            /**
             * Creates a new TranslationData instance using the specified properties.
             * @function create
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {instantbible.data.ITranslationData=} [properties] Properties to set
             * @returns {instantbible.data.TranslationData} TranslationData instance
             */
            TranslationData.create = function create(properties) {
                return new TranslationData(properties);
            };

            /**
             * Encodes the specified TranslationData message. Does not implicitly {@link instantbible.data.TranslationData.verify|verify} messages.
             * @function encode
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {instantbible.data.ITranslationData} message TranslationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TranslationData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.translation != null && Object.hasOwnProperty.call(message, "translation"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.translation);
                if (message.verses != null && message.verses.length)
                    for (var i = 0; i < message.verses.length; ++i)
                        $root.instantbible.data.VerseText.encode(message.verses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TranslationData message, length delimited. Does not implicitly {@link instantbible.data.TranslationData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {instantbible.data.ITranslationData} message TranslationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TranslationData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TranslationData message from the specified reader or buffer.
             * @function decode
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {instantbible.data.TranslationData} TranslationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TranslationData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.data.TranslationData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.translation = reader.int32();
                        break;
                    case 2:
                        if (!(message.verses && message.verses.length))
                            message.verses = [];
                        message.verses.push($root.instantbible.data.VerseText.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TranslationData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {instantbible.data.TranslationData} TranslationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TranslationData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TranslationData message.
             * @function verify
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TranslationData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.translation != null && message.hasOwnProperty("translation"))
                    switch (message.translation) {
                    default:
                        return "translation: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.verses != null && message.hasOwnProperty("verses")) {
                    if (!Array.isArray(message.verses))
                        return "verses: array expected";
                    for (var i = 0; i < message.verses.length; ++i) {
                        var error = $root.instantbible.data.VerseText.verify(message.verses[i]);
                        if (error)
                            return "verses." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TranslationData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {instantbible.data.TranslationData} TranslationData
             */
            TranslationData.fromObject = function fromObject(object) {
                if (object instanceof $root.instantbible.data.TranslationData)
                    return object;
                var message = new $root.instantbible.data.TranslationData();
                switch (object.translation) {
                case "KJV":
                case 0:
                    message.translation = 0;
                    break;
                case "NET":
                case 1:
                    message.translation = 1;
                    break;
                case "BSB":
                case 2:
                    message.translation = 2;
                    break;
                case "TOTAL":
                case 3:
                    message.translation = 3;
                    break;
                }
                if (object.verses) {
                    if (!Array.isArray(object.verses))
                        throw TypeError(".instantbible.data.TranslationData.verses: array expected");
                    message.verses = [];
                    for (var i = 0; i < object.verses.length; ++i) {
                        if (typeof object.verses[i] !== "object")
                            throw TypeError(".instantbible.data.TranslationData.verses: object expected");
                        message.verses[i] = $root.instantbible.data.VerseText.fromObject(object.verses[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a TranslationData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof instantbible.data.TranslationData
             * @static
             * @param {instantbible.data.TranslationData} message TranslationData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TranslationData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.verses = [];
                if (options.defaults)
                    object.translation = options.enums === String ? "KJV" : 0;
                if (message.translation != null && message.hasOwnProperty("translation"))
                    object.translation = options.enums === String ? $root.instantbible.data.Translation[message.translation] : message.translation;
                if (message.verses && message.verses.length) {
                    object.verses = [];
                    for (var j = 0; j < message.verses.length; ++j)
                        object.verses[j] = $root.instantbible.data.VerseText.toObject(message.verses[j], options);
                }
                return object;
            };

            /**
             * Converts this TranslationData to JSON.
             * @function toJSON
             * @memberof instantbible.data.TranslationData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TranslationData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TranslationData;
        })();

        return data;
    })();

    instantbible.engine = (function() {

        /**
         * Namespace engine.
         * @memberof instantbible
         * @namespace
         */
        var engine = {};

        engine.ReverseIndexEntry = (function() {

            /**
             * Properties of a ReverseIndexEntry.
             * @memberof instantbible.engine
             * @interface IReverseIndexEntry
             * @property {Uint8Array|null} [mapBytes] ReverseIndexEntry mapBytes
             * @property {Array.<Uint8Array>|null} [countsMapData] ReverseIndexEntry countsMapData
             * @property {Array.<Uint8Array>|null} [highlightsMapData] ReverseIndexEntry highlightsMapData
             */

            /**
             * Constructs a new ReverseIndexEntry.
             * @memberof instantbible.engine
             * @classdesc Represents a ReverseIndexEntry.
             * @implements IReverseIndexEntry
             * @constructor
             * @param {instantbible.engine.IReverseIndexEntry=} [properties] Properties to set
             */
            function ReverseIndexEntry(properties) {
                this.countsMapData = [];
                this.highlightsMapData = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReverseIndexEntry mapBytes.
             * @member {Uint8Array} mapBytes
             * @memberof instantbible.engine.ReverseIndexEntry
             * @instance
             */
            ReverseIndexEntry.prototype.mapBytes = $util.newBuffer([]);

            /**
             * ReverseIndexEntry countsMapData.
             * @member {Array.<Uint8Array>} countsMapData
             * @memberof instantbible.engine.ReverseIndexEntry
             * @instance
             */
            ReverseIndexEntry.prototype.countsMapData = $util.emptyArray;

            /**
             * ReverseIndexEntry highlightsMapData.
             * @member {Array.<Uint8Array>} highlightsMapData
             * @memberof instantbible.engine.ReverseIndexEntry
             * @instance
             */
            ReverseIndexEntry.prototype.highlightsMapData = $util.emptyArray;

            /**
             * Creates a new ReverseIndexEntry instance using the specified properties.
             * @function create
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {instantbible.engine.IReverseIndexEntry=} [properties] Properties to set
             * @returns {instantbible.engine.ReverseIndexEntry} ReverseIndexEntry instance
             */
            ReverseIndexEntry.create = function create(properties) {
                return new ReverseIndexEntry(properties);
            };

            /**
             * Encodes the specified ReverseIndexEntry message. Does not implicitly {@link instantbible.engine.ReverseIndexEntry.verify|verify} messages.
             * @function encode
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {instantbible.engine.IReverseIndexEntry} message ReverseIndexEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReverseIndexEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapBytes != null && Object.hasOwnProperty.call(message, "mapBytes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.mapBytes);
                if (message.countsMapData != null && message.countsMapData.length)
                    for (var i = 0; i < message.countsMapData.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.countsMapData[i]);
                if (message.highlightsMapData != null && message.highlightsMapData.length)
                    for (var i = 0; i < message.highlightsMapData.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.highlightsMapData[i]);
                return writer;
            };

            /**
             * Encodes the specified ReverseIndexEntry message, length delimited. Does not implicitly {@link instantbible.engine.ReverseIndexEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {instantbible.engine.IReverseIndexEntry} message ReverseIndexEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReverseIndexEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReverseIndexEntry message from the specified reader or buffer.
             * @function decode
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {instantbible.engine.ReverseIndexEntry} ReverseIndexEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReverseIndexEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.engine.ReverseIndexEntry();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.mapBytes = reader.bytes();
                        break;
                    case 2:
                        if (!(message.countsMapData && message.countsMapData.length))
                            message.countsMapData = [];
                        message.countsMapData.push(reader.bytes());
                        break;
                    case 3:
                        if (!(message.highlightsMapData && message.highlightsMapData.length))
                            message.highlightsMapData = [];
                        message.highlightsMapData.push(reader.bytes());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReverseIndexEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {instantbible.engine.ReverseIndexEntry} ReverseIndexEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReverseIndexEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReverseIndexEntry message.
             * @function verify
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReverseIndexEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapBytes != null && message.hasOwnProperty("mapBytes"))
                    if (!(message.mapBytes && typeof message.mapBytes.length === "number" || $util.isString(message.mapBytes)))
                        return "mapBytes: buffer expected";
                if (message.countsMapData != null && message.hasOwnProperty("countsMapData")) {
                    if (!Array.isArray(message.countsMapData))
                        return "countsMapData: array expected";
                    for (var i = 0; i < message.countsMapData.length; ++i)
                        if (!(message.countsMapData[i] && typeof message.countsMapData[i].length === "number" || $util.isString(message.countsMapData[i])))
                            return "countsMapData: buffer[] expected";
                }
                if (message.highlightsMapData != null && message.hasOwnProperty("highlightsMapData")) {
                    if (!Array.isArray(message.highlightsMapData))
                        return "highlightsMapData: array expected";
                    for (var i = 0; i < message.highlightsMapData.length; ++i)
                        if (!(message.highlightsMapData[i] && typeof message.highlightsMapData[i].length === "number" || $util.isString(message.highlightsMapData[i])))
                            return "highlightsMapData: buffer[] expected";
                }
                return null;
            };

            /**
             * Creates a ReverseIndexEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {instantbible.engine.ReverseIndexEntry} ReverseIndexEntry
             */
            ReverseIndexEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.instantbible.engine.ReverseIndexEntry)
                    return object;
                var message = new $root.instantbible.engine.ReverseIndexEntry();
                if (object.mapBytes != null)
                    if (typeof object.mapBytes === "string")
                        $util.base64.decode(object.mapBytes, message.mapBytes = $util.newBuffer($util.base64.length(object.mapBytes)), 0);
                    else if (object.mapBytes.length)
                        message.mapBytes = object.mapBytes;
                if (object.countsMapData) {
                    if (!Array.isArray(object.countsMapData))
                        throw TypeError(".instantbible.engine.ReverseIndexEntry.countsMapData: array expected");
                    message.countsMapData = [];
                    for (var i = 0; i < object.countsMapData.length; ++i)
                        if (typeof object.countsMapData[i] === "string")
                            $util.base64.decode(object.countsMapData[i], message.countsMapData[i] = $util.newBuffer($util.base64.length(object.countsMapData[i])), 0);
                        else if (object.countsMapData[i].length)
                            message.countsMapData[i] = object.countsMapData[i];
                }
                if (object.highlightsMapData) {
                    if (!Array.isArray(object.highlightsMapData))
                        throw TypeError(".instantbible.engine.ReverseIndexEntry.highlightsMapData: array expected");
                    message.highlightsMapData = [];
                    for (var i = 0; i < object.highlightsMapData.length; ++i)
                        if (typeof object.highlightsMapData[i] === "string")
                            $util.base64.decode(object.highlightsMapData[i], message.highlightsMapData[i] = $util.newBuffer($util.base64.length(object.highlightsMapData[i])), 0);
                        else if (object.highlightsMapData[i].length)
                            message.highlightsMapData[i] = object.highlightsMapData[i];
                }
                return message;
            };

            /**
             * Creates a plain object from a ReverseIndexEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof instantbible.engine.ReverseIndexEntry
             * @static
             * @param {instantbible.engine.ReverseIndexEntry} message ReverseIndexEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReverseIndexEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.countsMapData = [];
                    object.highlightsMapData = [];
                }
                if (options.defaults)
                    if (options.bytes === String)
                        object.mapBytes = "";
                    else {
                        object.mapBytes = [];
                        if (options.bytes !== Array)
                            object.mapBytes = $util.newBuffer(object.mapBytes);
                    }
                if (message.mapBytes != null && message.hasOwnProperty("mapBytes"))
                    object.mapBytes = options.bytes === String ? $util.base64.encode(message.mapBytes, 0, message.mapBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.mapBytes) : message.mapBytes;
                if (message.countsMapData && message.countsMapData.length) {
                    object.countsMapData = [];
                    for (var j = 0; j < message.countsMapData.length; ++j)
                        object.countsMapData[j] = options.bytes === String ? $util.base64.encode(message.countsMapData[j], 0, message.countsMapData[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.countsMapData[j]) : message.countsMapData[j];
                }
                if (message.highlightsMapData && message.highlightsMapData.length) {
                    object.highlightsMapData = [];
                    for (var j = 0; j < message.highlightsMapData.length; ++j)
                        object.highlightsMapData[j] = options.bytes === String ? $util.base64.encode(message.highlightsMapData[j], 0, message.highlightsMapData[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.highlightsMapData[j]) : message.highlightsMapData[j];
                }
                return object;
            };

            /**
             * Converts this ReverseIndexEntry to JSON.
             * @function toJSON
             * @memberof instantbible.engine.ReverseIndexEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReverseIndexEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ReverseIndexEntry;
        })();

        engine.IndexData = (function() {

            /**
             * Properties of an IndexData.
             * @memberof instantbible.engine
             * @interface IIndexData
             * @property {Uint8Array|null} [fst] IndexData fst
             * @property {Array.<instantbible.engine.IReverseIndexEntry>|null} [reverseIndexEntries] IndexData reverseIndexEntries
             * @property {Array.<string>|null} [highlightWords] IndexData highlightWords
             * @property {Uint8Array|null} [translationVerses] IndexData translationVerses
             * @property {Array.<string>|null} [translationVersesStrings] IndexData translationVersesStrings
             * @property {Uint8Array|null} [popularity] IndexData popularity
             */

            /**
             * Constructs a new IndexData.
             * @memberof instantbible.engine
             * @classdesc Represents an IndexData.
             * @implements IIndexData
             * @constructor
             * @param {instantbible.engine.IIndexData=} [properties] Properties to set
             */
            function IndexData(properties) {
                this.reverseIndexEntries = [];
                this.highlightWords = [];
                this.translationVersesStrings = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IndexData fst.
             * @member {Uint8Array} fst
             * @memberof instantbible.engine.IndexData
             * @instance
             */
            IndexData.prototype.fst = $util.newBuffer([]);

            /**
             * IndexData reverseIndexEntries.
             * @member {Array.<instantbible.engine.IReverseIndexEntry>} reverseIndexEntries
             * @memberof instantbible.engine.IndexData
             * @instance
             */
            IndexData.prototype.reverseIndexEntries = $util.emptyArray;

            /**
             * IndexData highlightWords.
             * @member {Array.<string>} highlightWords
             * @memberof instantbible.engine.IndexData
             * @instance
             */
            IndexData.prototype.highlightWords = $util.emptyArray;

            /**
             * IndexData translationVerses.
             * @member {Uint8Array} translationVerses
             * @memberof instantbible.engine.IndexData
             * @instance
             */
            IndexData.prototype.translationVerses = $util.newBuffer([]);

            /**
             * IndexData translationVersesStrings.
             * @member {Array.<string>} translationVersesStrings
             * @memberof instantbible.engine.IndexData
             * @instance
             */
            IndexData.prototype.translationVersesStrings = $util.emptyArray;

            /**
             * IndexData popularity.
             * @member {Uint8Array} popularity
             * @memberof instantbible.engine.IndexData
             * @instance
             */
            IndexData.prototype.popularity = $util.newBuffer([]);

            /**
             * Creates a new IndexData instance using the specified properties.
             * @function create
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {instantbible.engine.IIndexData=} [properties] Properties to set
             * @returns {instantbible.engine.IndexData} IndexData instance
             */
            IndexData.create = function create(properties) {
                return new IndexData(properties);
            };

            /**
             * Encodes the specified IndexData message. Does not implicitly {@link instantbible.engine.IndexData.verify|verify} messages.
             * @function encode
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {instantbible.engine.IIndexData} message IndexData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IndexData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fst != null && Object.hasOwnProperty.call(message, "fst"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fst);
                if (message.reverseIndexEntries != null && message.reverseIndexEntries.length)
                    for (var i = 0; i < message.reverseIndexEntries.length; ++i)
                        $root.instantbible.engine.ReverseIndexEntry.encode(message.reverseIndexEntries[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.highlightWords != null && message.highlightWords.length)
                    for (var i = 0; i < message.highlightWords.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.highlightWords[i]);
                if (message.translationVerses != null && Object.hasOwnProperty.call(message, "translationVerses"))
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.translationVerses);
                if (message.translationVersesStrings != null && message.translationVersesStrings.length)
                    for (var i = 0; i < message.translationVersesStrings.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.translationVersesStrings[i]);
                if (message.popularity != null && Object.hasOwnProperty.call(message, "popularity"))
                    writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.popularity);
                return writer;
            };

            /**
             * Encodes the specified IndexData message, length delimited. Does not implicitly {@link instantbible.engine.IndexData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {instantbible.engine.IIndexData} message IndexData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IndexData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an IndexData message from the specified reader or buffer.
             * @function decode
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {instantbible.engine.IndexData} IndexData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IndexData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.engine.IndexData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.fst = reader.bytes();
                        break;
                    case 2:
                        if (!(message.reverseIndexEntries && message.reverseIndexEntries.length))
                            message.reverseIndexEntries = [];
                        message.reverseIndexEntries.push($root.instantbible.engine.ReverseIndexEntry.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.highlightWords && message.highlightWords.length))
                            message.highlightWords = [];
                        message.highlightWords.push(reader.string());
                        break;
                    case 4:
                        message.translationVerses = reader.bytes();
                        break;
                    case 5:
                        if (!(message.translationVersesStrings && message.translationVersesStrings.length))
                            message.translationVersesStrings = [];
                        message.translationVersesStrings.push(reader.string());
                        break;
                    case 6:
                        message.popularity = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an IndexData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {instantbible.engine.IndexData} IndexData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IndexData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an IndexData message.
             * @function verify
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IndexData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fst != null && message.hasOwnProperty("fst"))
                    if (!(message.fst && typeof message.fst.length === "number" || $util.isString(message.fst)))
                        return "fst: buffer expected";
                if (message.reverseIndexEntries != null && message.hasOwnProperty("reverseIndexEntries")) {
                    if (!Array.isArray(message.reverseIndexEntries))
                        return "reverseIndexEntries: array expected";
                    for (var i = 0; i < message.reverseIndexEntries.length; ++i) {
                        var error = $root.instantbible.engine.ReverseIndexEntry.verify(message.reverseIndexEntries[i]);
                        if (error)
                            return "reverseIndexEntries." + error;
                    }
                }
                if (message.highlightWords != null && message.hasOwnProperty("highlightWords")) {
                    if (!Array.isArray(message.highlightWords))
                        return "highlightWords: array expected";
                    for (var i = 0; i < message.highlightWords.length; ++i)
                        if (!$util.isString(message.highlightWords[i]))
                            return "highlightWords: string[] expected";
                }
                if (message.translationVerses != null && message.hasOwnProperty("translationVerses"))
                    if (!(message.translationVerses && typeof message.translationVerses.length === "number" || $util.isString(message.translationVerses)))
                        return "translationVerses: buffer expected";
                if (message.translationVersesStrings != null && message.hasOwnProperty("translationVersesStrings")) {
                    if (!Array.isArray(message.translationVersesStrings))
                        return "translationVersesStrings: array expected";
                    for (var i = 0; i < message.translationVersesStrings.length; ++i)
                        if (!$util.isString(message.translationVersesStrings[i]))
                            return "translationVersesStrings: string[] expected";
                }
                if (message.popularity != null && message.hasOwnProperty("popularity"))
                    if (!(message.popularity && typeof message.popularity.length === "number" || $util.isString(message.popularity)))
                        return "popularity: buffer expected";
                return null;
            };

            /**
             * Creates an IndexData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {instantbible.engine.IndexData} IndexData
             */
            IndexData.fromObject = function fromObject(object) {
                if (object instanceof $root.instantbible.engine.IndexData)
                    return object;
                var message = new $root.instantbible.engine.IndexData();
                if (object.fst != null)
                    if (typeof object.fst === "string")
                        $util.base64.decode(object.fst, message.fst = $util.newBuffer($util.base64.length(object.fst)), 0);
                    else if (object.fst.length)
                        message.fst = object.fst;
                if (object.reverseIndexEntries) {
                    if (!Array.isArray(object.reverseIndexEntries))
                        throw TypeError(".instantbible.engine.IndexData.reverseIndexEntries: array expected");
                    message.reverseIndexEntries = [];
                    for (var i = 0; i < object.reverseIndexEntries.length; ++i) {
                        if (typeof object.reverseIndexEntries[i] !== "object")
                            throw TypeError(".instantbible.engine.IndexData.reverseIndexEntries: object expected");
                        message.reverseIndexEntries[i] = $root.instantbible.engine.ReverseIndexEntry.fromObject(object.reverseIndexEntries[i]);
                    }
                }
                if (object.highlightWords) {
                    if (!Array.isArray(object.highlightWords))
                        throw TypeError(".instantbible.engine.IndexData.highlightWords: array expected");
                    message.highlightWords = [];
                    for (var i = 0; i < object.highlightWords.length; ++i)
                        message.highlightWords[i] = String(object.highlightWords[i]);
                }
                if (object.translationVerses != null)
                    if (typeof object.translationVerses === "string")
                        $util.base64.decode(object.translationVerses, message.translationVerses = $util.newBuffer($util.base64.length(object.translationVerses)), 0);
                    else if (object.translationVerses.length)
                        message.translationVerses = object.translationVerses;
                if (object.translationVersesStrings) {
                    if (!Array.isArray(object.translationVersesStrings))
                        throw TypeError(".instantbible.engine.IndexData.translationVersesStrings: array expected");
                    message.translationVersesStrings = [];
                    for (var i = 0; i < object.translationVersesStrings.length; ++i)
                        message.translationVersesStrings[i] = String(object.translationVersesStrings[i]);
                }
                if (object.popularity != null)
                    if (typeof object.popularity === "string")
                        $util.base64.decode(object.popularity, message.popularity = $util.newBuffer($util.base64.length(object.popularity)), 0);
                    else if (object.popularity.length)
                        message.popularity = object.popularity;
                return message;
            };

            /**
             * Creates a plain object from an IndexData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof instantbible.engine.IndexData
             * @static
             * @param {instantbible.engine.IndexData} message IndexData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IndexData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.reverseIndexEntries = [];
                    object.highlightWords = [];
                    object.translationVersesStrings = [];
                }
                if (options.defaults) {
                    if (options.bytes === String)
                        object.fst = "";
                    else {
                        object.fst = [];
                        if (options.bytes !== Array)
                            object.fst = $util.newBuffer(object.fst);
                    }
                    if (options.bytes === String)
                        object.translationVerses = "";
                    else {
                        object.translationVerses = [];
                        if (options.bytes !== Array)
                            object.translationVerses = $util.newBuffer(object.translationVerses);
                    }
                    if (options.bytes === String)
                        object.popularity = "";
                    else {
                        object.popularity = [];
                        if (options.bytes !== Array)
                            object.popularity = $util.newBuffer(object.popularity);
                    }
                }
                if (message.fst != null && message.hasOwnProperty("fst"))
                    object.fst = options.bytes === String ? $util.base64.encode(message.fst, 0, message.fst.length) : options.bytes === Array ? Array.prototype.slice.call(message.fst) : message.fst;
                if (message.reverseIndexEntries && message.reverseIndexEntries.length) {
                    object.reverseIndexEntries = [];
                    for (var j = 0; j < message.reverseIndexEntries.length; ++j)
                        object.reverseIndexEntries[j] = $root.instantbible.engine.ReverseIndexEntry.toObject(message.reverseIndexEntries[j], options);
                }
                if (message.highlightWords && message.highlightWords.length) {
                    object.highlightWords = [];
                    for (var j = 0; j < message.highlightWords.length; ++j)
                        object.highlightWords[j] = message.highlightWords[j];
                }
                if (message.translationVerses != null && message.hasOwnProperty("translationVerses"))
                    object.translationVerses = options.bytes === String ? $util.base64.encode(message.translationVerses, 0, message.translationVerses.length) : options.bytes === Array ? Array.prototype.slice.call(message.translationVerses) : message.translationVerses;
                if (message.translationVersesStrings && message.translationVersesStrings.length) {
                    object.translationVersesStrings = [];
                    for (var j = 0; j < message.translationVersesStrings.length; ++j)
                        object.translationVersesStrings[j] = message.translationVersesStrings[j];
                }
                if (message.popularity != null && message.hasOwnProperty("popularity"))
                    object.popularity = options.bytes === String ? $util.base64.encode(message.popularity, 0, message.popularity.length) : options.bytes === Array ? Array.prototype.slice.call(message.popularity) : message.popularity;
                return object;
            };

            /**
             * Converts this IndexData to JSON.
             * @function toJSON
             * @memberof instantbible.engine.IndexData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IndexData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IndexData;
        })();

        return engine;
    })();

    instantbible.service = (function() {

        /**
         * Namespace service.
         * @memberof instantbible
         * @namespace
         */
        var service = {};

        service.Response = (function() {

            /**
             * Properties of a Response.
             * @memberof instantbible.service
             * @interface IResponse
             * @property {Array.<instantbible.service.Response.IVerseResult>|null} [results] Response results
             * @property {instantbible.service.Response.ITimings|null} [timings] Response timings
             */

            /**
             * Constructs a new Response.
             * @memberof instantbible.service
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {instantbible.service.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                this.results = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Response results.
             * @member {Array.<instantbible.service.Response.IVerseResult>} results
             * @memberof instantbible.service.Response
             * @instance
             */
            Response.prototype.results = $util.emptyArray;

            /**
             * Response timings.
             * @member {instantbible.service.Response.ITimings|null|undefined} timings
             * @memberof instantbible.service.Response
             * @instance
             */
            Response.prototype.timings = null;

            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof instantbible.service.Response
             * @static
             * @param {instantbible.service.IResponse=} [properties] Properties to set
             * @returns {instantbible.service.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };

            /**
             * Encodes the specified Response message. Does not implicitly {@link instantbible.service.Response.verify|verify} messages.
             * @function encode
             * @memberof instantbible.service.Response
             * @static
             * @param {instantbible.service.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.results != null && message.results.length)
                    for (var i = 0; i < message.results.length; ++i)
                        $root.instantbible.service.Response.VerseResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.timings != null && Object.hasOwnProperty.call(message, "timings"))
                    $root.instantbible.service.Response.Timings.encode(message.timings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link instantbible.service.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof instantbible.service.Response
             * @static
             * @param {instantbible.service.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof instantbible.service.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {instantbible.service.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.service.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.results && message.results.length))
                            message.results = [];
                        message.results.push($root.instantbible.service.Response.VerseResult.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.timings = $root.instantbible.service.Response.Timings.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof instantbible.service.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {instantbible.service.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Response message.
             * @function verify
             * @memberof instantbible.service.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.results != null && message.hasOwnProperty("results")) {
                    if (!Array.isArray(message.results))
                        return "results: array expected";
                    for (var i = 0; i < message.results.length; ++i) {
                        var error = $root.instantbible.service.Response.VerseResult.verify(message.results[i]);
                        if (error)
                            return "results." + error;
                    }
                }
                if (message.timings != null && message.hasOwnProperty("timings")) {
                    var error = $root.instantbible.service.Response.Timings.verify(message.timings);
                    if (error)
                        return "timings." + error;
                }
                return null;
            };

            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof instantbible.service.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {instantbible.service.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.instantbible.service.Response)
                    return object;
                var message = new $root.instantbible.service.Response();
                if (object.results) {
                    if (!Array.isArray(object.results))
                        throw TypeError(".instantbible.service.Response.results: array expected");
                    message.results = [];
                    for (var i = 0; i < object.results.length; ++i) {
                        if (typeof object.results[i] !== "object")
                            throw TypeError(".instantbible.service.Response.results: object expected");
                        message.results[i] = $root.instantbible.service.Response.VerseResult.fromObject(object.results[i]);
                    }
                }
                if (object.timings != null) {
                    if (typeof object.timings !== "object")
                        throw TypeError(".instantbible.service.Response.timings: object expected");
                    message.timings = $root.instantbible.service.Response.Timings.fromObject(object.timings);
                }
                return message;
            };

            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof instantbible.service.Response
             * @static
             * @param {instantbible.service.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.results = [];
                if (options.defaults)
                    object.timings = null;
                if (message.results && message.results.length) {
                    object.results = [];
                    for (var j = 0; j < message.results.length; ++j)
                        object.results[j] = $root.instantbible.service.Response.VerseResult.toObject(message.results[j], options);
                }
                if (message.timings != null && message.hasOwnProperty("timings"))
                    object.timings = $root.instantbible.service.Response.Timings.toObject(message.timings, options);
                return object;
            };

            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof instantbible.service.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Response.VerseResult = (function() {

                /**
                 * Properties of a VerseResult.
                 * @memberof instantbible.service.Response
                 * @interface IVerseResult
                 * @property {instantbible.data.IVerseKey|null} [key] VerseResult key
                 * @property {instantbible.data.Translation|null} [topTranslation] VerseResult topTranslation
                 * @property {Array.<string>|null} [text] VerseResult text
                 * @property {Array.<string>|null} [highlights] VerseResult highlights
                 * @property {Array.<instantbible.service.Response.VerseResult.IRanking>|null} [rankings] VerseResult rankings
                 * @property {number|null} [popularity] VerseResult popularity
                 */

                /**
                 * Constructs a new VerseResult.
                 * @memberof instantbible.service.Response
                 * @classdesc Represents a VerseResult.
                 * @implements IVerseResult
                 * @constructor
                 * @param {instantbible.service.Response.IVerseResult=} [properties] Properties to set
                 */
                function VerseResult(properties) {
                    this.text = [];
                    this.highlights = [];
                    this.rankings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerseResult key.
                 * @member {instantbible.data.IVerseKey|null|undefined} key
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 */
                VerseResult.prototype.key = null;

                /**
                 * VerseResult topTranslation.
                 * @member {instantbible.data.Translation} topTranslation
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 */
                VerseResult.prototype.topTranslation = 0;

                /**
                 * VerseResult text.
                 * @member {Array.<string>} text
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 */
                VerseResult.prototype.text = $util.emptyArray;

                /**
                 * VerseResult highlights.
                 * @member {Array.<string>} highlights
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 */
                VerseResult.prototype.highlights = $util.emptyArray;

                /**
                 * VerseResult rankings.
                 * @member {Array.<instantbible.service.Response.VerseResult.IRanking>} rankings
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 */
                VerseResult.prototype.rankings = $util.emptyArray;

                /**
                 * VerseResult popularity.
                 * @member {number} popularity
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 */
                VerseResult.prototype.popularity = 0;

                /**
                 * Creates a new VerseResult instance using the specified properties.
                 * @function create
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {instantbible.service.Response.IVerseResult=} [properties] Properties to set
                 * @returns {instantbible.service.Response.VerseResult} VerseResult instance
                 */
                VerseResult.create = function create(properties) {
                    return new VerseResult(properties);
                };

                /**
                 * Encodes the specified VerseResult message. Does not implicitly {@link instantbible.service.Response.VerseResult.verify|verify} messages.
                 * @function encode
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {instantbible.service.Response.IVerseResult} message VerseResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VerseResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                        $root.instantbible.data.VerseKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.topTranslation != null && Object.hasOwnProperty.call(message, "topTranslation"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.topTranslation);
                    if (message.text != null && message.text.length)
                        for (var i = 0; i < message.text.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.text[i]);
                    if (message.highlights != null && message.highlights.length)
                        for (var i = 0; i < message.highlights.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.highlights[i]);
                    if (message.rankings != null && message.rankings.length)
                        for (var i = 0; i < message.rankings.length; ++i)
                            $root.instantbible.service.Response.VerseResult.Ranking.encode(message.rankings[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.popularity != null && Object.hasOwnProperty.call(message, "popularity"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.popularity);
                    return writer;
                };

                /**
                 * Encodes the specified VerseResult message, length delimited. Does not implicitly {@link instantbible.service.Response.VerseResult.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {instantbible.service.Response.IVerseResult} message VerseResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VerseResult.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VerseResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {instantbible.service.Response.VerseResult} VerseResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VerseResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.service.Response.VerseResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.key = $root.instantbible.data.VerseKey.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.topTranslation = reader.int32();
                            break;
                        case 3:
                            if (!(message.text && message.text.length))
                                message.text = [];
                            message.text.push(reader.string());
                            break;
                        case 4:
                            if (!(message.highlights && message.highlights.length))
                                message.highlights = [];
                            message.highlights.push(reader.string());
                            break;
                        case 5:
                            if (!(message.rankings && message.rankings.length))
                                message.rankings = [];
                            message.rankings.push($root.instantbible.service.Response.VerseResult.Ranking.decode(reader, reader.uint32()));
                            break;
                        case 6:
                            message.popularity = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VerseResult message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {instantbible.service.Response.VerseResult} VerseResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VerseResult.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VerseResult message.
                 * @function verify
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerseResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key")) {
                        var error = $root.instantbible.data.VerseKey.verify(message.key);
                        if (error)
                            return "key." + error;
                    }
                    if (message.topTranslation != null && message.hasOwnProperty("topTranslation"))
                        switch (message.topTranslation) {
                        default:
                            return "topTranslation: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.text != null && message.hasOwnProperty("text")) {
                        if (!Array.isArray(message.text))
                            return "text: array expected";
                        for (var i = 0; i < message.text.length; ++i)
                            if (!$util.isString(message.text[i]))
                                return "text: string[] expected";
                    }
                    if (message.highlights != null && message.hasOwnProperty("highlights")) {
                        if (!Array.isArray(message.highlights))
                            return "highlights: array expected";
                        for (var i = 0; i < message.highlights.length; ++i)
                            if (!$util.isString(message.highlights[i]))
                                return "highlights: string[] expected";
                    }
                    if (message.rankings != null && message.hasOwnProperty("rankings")) {
                        if (!Array.isArray(message.rankings))
                            return "rankings: array expected";
                        for (var i = 0; i < message.rankings.length; ++i) {
                            var error = $root.instantbible.service.Response.VerseResult.Ranking.verify(message.rankings[i]);
                            if (error)
                                return "rankings." + error;
                        }
                    }
                    if (message.popularity != null && message.hasOwnProperty("popularity"))
                        if (!$util.isInteger(message.popularity))
                            return "popularity: integer expected";
                    return null;
                };

                /**
                 * Creates a VerseResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {instantbible.service.Response.VerseResult} VerseResult
                 */
                VerseResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.instantbible.service.Response.VerseResult)
                        return object;
                    var message = new $root.instantbible.service.Response.VerseResult();
                    if (object.key != null) {
                        if (typeof object.key !== "object")
                            throw TypeError(".instantbible.service.Response.VerseResult.key: object expected");
                        message.key = $root.instantbible.data.VerseKey.fromObject(object.key);
                    }
                    switch (object.topTranslation) {
                    case "KJV":
                    case 0:
                        message.topTranslation = 0;
                        break;
                    case "NET":
                    case 1:
                        message.topTranslation = 1;
                        break;
                    case "BSB":
                    case 2:
                        message.topTranslation = 2;
                        break;
                    case "TOTAL":
                    case 3:
                        message.topTranslation = 3;
                        break;
                    }
                    if (object.text) {
                        if (!Array.isArray(object.text))
                            throw TypeError(".instantbible.service.Response.VerseResult.text: array expected");
                        message.text = [];
                        for (var i = 0; i < object.text.length; ++i)
                            message.text[i] = String(object.text[i]);
                    }
                    if (object.highlights) {
                        if (!Array.isArray(object.highlights))
                            throw TypeError(".instantbible.service.Response.VerseResult.highlights: array expected");
                        message.highlights = [];
                        for (var i = 0; i < object.highlights.length; ++i)
                            message.highlights[i] = String(object.highlights[i]);
                    }
                    if (object.rankings) {
                        if (!Array.isArray(object.rankings))
                            throw TypeError(".instantbible.service.Response.VerseResult.rankings: array expected");
                        message.rankings = [];
                        for (var i = 0; i < object.rankings.length; ++i) {
                            if (typeof object.rankings[i] !== "object")
                                throw TypeError(".instantbible.service.Response.VerseResult.rankings: object expected");
                            message.rankings[i] = $root.instantbible.service.Response.VerseResult.Ranking.fromObject(object.rankings[i]);
                        }
                    }
                    if (object.popularity != null)
                        message.popularity = object.popularity | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a VerseResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof instantbible.service.Response.VerseResult
                 * @static
                 * @param {instantbible.service.Response.VerseResult} message VerseResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerseResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.text = [];
                        object.highlights = [];
                        object.rankings = [];
                    }
                    if (options.defaults) {
                        object.key = null;
                        object.topTranslation = options.enums === String ? "KJV" : 0;
                        object.popularity = 0;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = $root.instantbible.data.VerseKey.toObject(message.key, options);
                    if (message.topTranslation != null && message.hasOwnProperty("topTranslation"))
                        object.topTranslation = options.enums === String ? $root.instantbible.data.Translation[message.topTranslation] : message.topTranslation;
                    if (message.text && message.text.length) {
                        object.text = [];
                        for (var j = 0; j < message.text.length; ++j)
                            object.text[j] = message.text[j];
                    }
                    if (message.highlights && message.highlights.length) {
                        object.highlights = [];
                        for (var j = 0; j < message.highlights.length; ++j)
                            object.highlights[j] = message.highlights[j];
                    }
                    if (message.rankings && message.rankings.length) {
                        object.rankings = [];
                        for (var j = 0; j < message.rankings.length; ++j)
                            object.rankings[j] = $root.instantbible.service.Response.VerseResult.Ranking.toObject(message.rankings[j], options);
                    }
                    if (message.popularity != null && message.hasOwnProperty("popularity"))
                        object.popularity = message.popularity;
                    return object;
                };

                /**
                 * Converts this VerseResult to JSON.
                 * @function toJSON
                 * @memberof instantbible.service.Response.VerseResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerseResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                VerseResult.Ranking = (function() {

                    /**
                     * Properties of a Ranking.
                     * @memberof instantbible.service.Response.VerseResult
                     * @interface IRanking
                     * @property {number|null} [typos] Ranking typos
                     * @property {number|null} [queryWords] Ranking queryWords
                     * @property {number|null} [exact] Ranking exact
                     */

                    /**
                     * Constructs a new Ranking.
                     * @memberof instantbible.service.Response.VerseResult
                     * @classdesc Represents a Ranking.
                     * @implements IRanking
                     * @constructor
                     * @param {instantbible.service.Response.VerseResult.IRanking=} [properties] Properties to set
                     */
                    function Ranking(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Ranking typos.
                     * @member {number} typos
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @instance
                     */
                    Ranking.prototype.typos = 0;

                    /**
                     * Ranking queryWords.
                     * @member {number} queryWords
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @instance
                     */
                    Ranking.prototype.queryWords = 0;

                    /**
                     * Ranking exact.
                     * @member {number} exact
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @instance
                     */
                    Ranking.prototype.exact = 0;

                    /**
                     * Creates a new Ranking instance using the specified properties.
                     * @function create
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {instantbible.service.Response.VerseResult.IRanking=} [properties] Properties to set
                     * @returns {instantbible.service.Response.VerseResult.Ranking} Ranking instance
                     */
                    Ranking.create = function create(properties) {
                        return new Ranking(properties);
                    };

                    /**
                     * Encodes the specified Ranking message. Does not implicitly {@link instantbible.service.Response.VerseResult.Ranking.verify|verify} messages.
                     * @function encode
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {instantbible.service.Response.VerseResult.IRanking} message Ranking message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Ranking.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.typos != null && Object.hasOwnProperty.call(message, "typos"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.typos);
                        if (message.queryWords != null && Object.hasOwnProperty.call(message, "queryWords"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.queryWords);
                        if (message.exact != null && Object.hasOwnProperty.call(message, "exact"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.exact);
                        return writer;
                    };

                    /**
                     * Encodes the specified Ranking message, length delimited. Does not implicitly {@link instantbible.service.Response.VerseResult.Ranking.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {instantbible.service.Response.VerseResult.IRanking} message Ranking message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Ranking.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Ranking message from the specified reader or buffer.
                     * @function decode
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {instantbible.service.Response.VerseResult.Ranking} Ranking
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Ranking.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.service.Response.VerseResult.Ranking();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.typos = reader.int32();
                                break;
                            case 2:
                                message.queryWords = reader.int32();
                                break;
                            case 3:
                                message.exact = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Ranking message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {instantbible.service.Response.VerseResult.Ranking} Ranking
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Ranking.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Ranking message.
                     * @function verify
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Ranking.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.typos != null && message.hasOwnProperty("typos"))
                            if (!$util.isInteger(message.typos))
                                return "typos: integer expected";
                        if (message.queryWords != null && message.hasOwnProperty("queryWords"))
                            if (!$util.isInteger(message.queryWords))
                                return "queryWords: integer expected";
                        if (message.exact != null && message.hasOwnProperty("exact"))
                            if (!$util.isInteger(message.exact))
                                return "exact: integer expected";
                        return null;
                    };

                    /**
                     * Creates a Ranking message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {instantbible.service.Response.VerseResult.Ranking} Ranking
                     */
                    Ranking.fromObject = function fromObject(object) {
                        if (object instanceof $root.instantbible.service.Response.VerseResult.Ranking)
                            return object;
                        var message = new $root.instantbible.service.Response.VerseResult.Ranking();
                        if (object.typos != null)
                            message.typos = object.typos | 0;
                        if (object.queryWords != null)
                            message.queryWords = object.queryWords | 0;
                        if (object.exact != null)
                            message.exact = object.exact | 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from a Ranking message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @static
                     * @param {instantbible.service.Response.VerseResult.Ranking} message Ranking
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Ranking.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.typos = 0;
                            object.queryWords = 0;
                            object.exact = 0;
                        }
                        if (message.typos != null && message.hasOwnProperty("typos"))
                            object.typos = message.typos;
                        if (message.queryWords != null && message.hasOwnProperty("queryWords"))
                            object.queryWords = message.queryWords;
                        if (message.exact != null && message.hasOwnProperty("exact"))
                            object.exact = message.exact;
                        return object;
                    };

                    /**
                     * Converts this Ranking to JSON.
                     * @function toJSON
                     * @memberof instantbible.service.Response.VerseResult.Ranking
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Ranking.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Ranking;
                })();

                return VerseResult;
            })();

            Response.Timings = (function() {

                /**
                 * Properties of a Timings.
                 * @memberof instantbible.service.Response
                 * @interface ITimings
                 * @property {number|null} [tokenize] Timings tokenize
                 * @property {number|null} [fst] Timings fst
                 * @property {number|null} [score] Timings score
                 * @property {number|null} [rank] Timings rank
                 * @property {number|null} [total] Timings total
                 */

                /**
                 * Constructs a new Timings.
                 * @memberof instantbible.service.Response
                 * @classdesc Represents a Timings.
                 * @implements ITimings
                 * @constructor
                 * @param {instantbible.service.Response.ITimings=} [properties] Properties to set
                 */
                function Timings(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Timings tokenize.
                 * @member {number} tokenize
                 * @memberof instantbible.service.Response.Timings
                 * @instance
                 */
                Timings.prototype.tokenize = 0;

                /**
                 * Timings fst.
                 * @member {number} fst
                 * @memberof instantbible.service.Response.Timings
                 * @instance
                 */
                Timings.prototype.fst = 0;

                /**
                 * Timings score.
                 * @member {number} score
                 * @memberof instantbible.service.Response.Timings
                 * @instance
                 */
                Timings.prototype.score = 0;

                /**
                 * Timings rank.
                 * @member {number} rank
                 * @memberof instantbible.service.Response.Timings
                 * @instance
                 */
                Timings.prototype.rank = 0;

                /**
                 * Timings total.
                 * @member {number} total
                 * @memberof instantbible.service.Response.Timings
                 * @instance
                 */
                Timings.prototype.total = 0;

                /**
                 * Creates a new Timings instance using the specified properties.
                 * @function create
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {instantbible.service.Response.ITimings=} [properties] Properties to set
                 * @returns {instantbible.service.Response.Timings} Timings instance
                 */
                Timings.create = function create(properties) {
                    return new Timings(properties);
                };

                /**
                 * Encodes the specified Timings message. Does not implicitly {@link instantbible.service.Response.Timings.verify|verify} messages.
                 * @function encode
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {instantbible.service.Response.ITimings} message Timings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timings.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tokenize != null && Object.hasOwnProperty.call(message, "tokenize"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tokenize);
                    if (message.fst != null && Object.hasOwnProperty.call(message, "fst"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.fst);
                    if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.score);
                    if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.rank);
                    if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.total);
                    return writer;
                };

                /**
                 * Encodes the specified Timings message, length delimited. Does not implicitly {@link instantbible.service.Response.Timings.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {instantbible.service.Response.ITimings} message Timings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timings.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Timings message from the specified reader or buffer.
                 * @function decode
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {instantbible.service.Response.Timings} Timings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timings.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.instantbible.service.Response.Timings();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.tokenize = reader.int32();
                            break;
                        case 2:
                            message.fst = reader.int32();
                            break;
                        case 3:
                            message.score = reader.int32();
                            break;
                        case 4:
                            message.rank = reader.int32();
                            break;
                        case 5:
                            message.total = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Timings message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {instantbible.service.Response.Timings} Timings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timings.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Timings message.
                 * @function verify
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timings.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tokenize != null && message.hasOwnProperty("tokenize"))
                        if (!$util.isInteger(message.tokenize))
                            return "tokenize: integer expected";
                    if (message.fst != null && message.hasOwnProperty("fst"))
                        if (!$util.isInteger(message.fst))
                            return "fst: integer expected";
                    if (message.score != null && message.hasOwnProperty("score"))
                        if (!$util.isInteger(message.score))
                            return "score: integer expected";
                    if (message.rank != null && message.hasOwnProperty("rank"))
                        if (!$util.isInteger(message.rank))
                            return "rank: integer expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total))
                            return "total: integer expected";
                    return null;
                };

                /**
                 * Creates a Timings message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {instantbible.service.Response.Timings} Timings
                 */
                Timings.fromObject = function fromObject(object) {
                    if (object instanceof $root.instantbible.service.Response.Timings)
                        return object;
                    var message = new $root.instantbible.service.Response.Timings();
                    if (object.tokenize != null)
                        message.tokenize = object.tokenize | 0;
                    if (object.fst != null)
                        message.fst = object.fst | 0;
                    if (object.score != null)
                        message.score = object.score | 0;
                    if (object.rank != null)
                        message.rank = object.rank | 0;
                    if (object.total != null)
                        message.total = object.total | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a Timings message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof instantbible.service.Response.Timings
                 * @static
                 * @param {instantbible.service.Response.Timings} message Timings
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timings.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.tokenize = 0;
                        object.fst = 0;
                        object.score = 0;
                        object.rank = 0;
                        object.total = 0;
                    }
                    if (message.tokenize != null && message.hasOwnProperty("tokenize"))
                        object.tokenize = message.tokenize;
                    if (message.fst != null && message.hasOwnProperty("fst"))
                        object.fst = message.fst;
                    if (message.score != null && message.hasOwnProperty("score"))
                        object.score = message.score;
                    if (message.rank != null && message.hasOwnProperty("rank"))
                        object.rank = message.rank;
                    if (message.total != null && message.hasOwnProperty("total"))
                        object.total = message.total;
                    return object;
                };

                /**
                 * Converts this Timings to JSON.
                 * @function toJSON
                 * @memberof instantbible.service.Response.Timings
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timings.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Timings;
            })();

            return Response;
        })();

        return service;
    })();

    return instantbible;
})();

module.exports = $root;
