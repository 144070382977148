import { instantbible as proto } from '../../proto';

const { Book } = proto.data;

export default {
  [Book.GENESIS]: 'Genesis',
  [Book.EXODUS]: 'Exodus',
  [Book.LEVITICUS]: 'Leviticus',
  [Book.NUMBERS]: 'Numbers',
  [Book.DEUTERONOMY]: 'Deuteronomy',
  [Book.JOSHUA]: 'Joshua',
  [Book.JUDGES]: 'Judges',
  [Book.RUTH]: 'Ruth',
  [Book.FIRST_SAMUEL]: '1 Samuel',
  [Book.SECOND_SAMUEL]: '2 Samuel',
  [Book.FIRST_KINGS]: '1 Kings',
  [Book.SECOND_KINGS]: '2 Kings',
  [Book.FIRST_CHRONICLES]: '1 Chronicles',
  [Book.SECOND_CHRONICLES]: '2 Chronicles',
  [Book.EZRA]: 'Ezra',
  [Book.NEHEMIAH]: 'Nehemiah',
  [Book.ESTHER]: 'Esther',
  [Book.JOB]: 'Job',
  [Book.PSALMS]: 'Psalms',
  [Book.PROVERBS]: 'Proverbs',
  [Book.ECCLESIASTES]: 'Ecclesiastes',
  [Book.SONG_OF_SOLOMON]: 'Song of Solomon',
  [Book.ISAIAH]: 'Isaiah',
  [Book.JEREMIAH]: 'Jeremiah',
  [Book.LAMENTATIONS]: 'Lamentations',
  [Book.EZEKIEL]: 'Ezekiel',
  [Book.DANIEL]: 'Daniel',
  [Book.HOSEA]: 'Hosea',
  [Book.JOEL]: 'Joel',
  [Book.AMOS]: 'Amos',
  [Book.OBADIAH]: 'Obadiah',
  [Book.JONAH]: 'Jonah',
  [Book.MICAH]: 'Micha',
  [Book.NAHUM]: 'Nahum',
  [Book.HABAKKUK]: 'Habakkuk',
  [Book.ZEPHANIAH]: 'Zephaniah',
  [Book.HAGGAI]: 'Haggai',
  [Book.ZECHARIAH]: 'Zechariah',
  [Book.MALACHI]: 'Malachi',
  [Book.MATTHEW]: 'Matthew',
  [Book.MARK]: 'Mark',
  [Book.LUKE]: 'Luke',
  [Book.JOHN]: 'John',
  [Book.ACTS]: 'Acts',
  [Book.ROMANS]: 'Romans',
  [Book.FIRST_CORINTHIANS]: '1 Corinthians',
  [Book.SECOND_CORINTHIANS]: '2 Corinthians',
  [Book.GALATIANS]: 'Galatians',
  [Book.EPHESIANS]: 'Ephesians',
  [Book.PHILIPPIANS]: 'Philippians',
  [Book.COLOSSIANS]: 'Colossians',
  [Book.FIRST_THESSALONIANS]: '1 Thessalonians',
  [Book.SECOND_THESSALONIANS]: '2 Thessalonians',
  [Book.FIRST_TIMOTHY]: '1 Timothy',
  [Book.SECOND_TIMOTHY]: '2 Timothy',
  [Book.TITUS]: 'Titus',
  [Book.PHILEMON]: 'Philemon',
  [Book.HEBREWS]: 'Hebrews',
  [Book.JAMES]: 'James',
  [Book.FIRST_PETER]: '1 Peter',
  [Book.SECOND_PETER]: '2 Peter',
  [Book.FIRST_JOHN]: '1 John',
  [Book.SECOND_JOHN]: '2 John',
  [Book.THIRD_JOHN]: '3 John',
  [Book.JUDE]: 'Jude',
  [Book.REVELATION]: 'Revelation',
};
